// export const API_URL = 'https://kkoomming.kr/api/ADMIN/v1.0'
// export const API_URL = 'https://kkoomming.co.kr/api/ADMIN/v1.0/'
// export const API_URL = 'https://kkoomming.kr/api/ADMIN/v1.5/'
// export const API_URL = 'https://kkoomming.net/api/ADMIN/v1.5/'


//
// export const API_URL = 'http://localhost:3000/api/ADMIN/v1.5/'
// export const API_URL2 = 'http://localhost:8080/api/ADMIN/v3.0/'
//
// export const API_URL = 'https://kkoomming.net/api/ADMIN/v1.5/'
// export const API_URL2 = 'https://kkoomming.net/api/ADMIN/v3.0/'
//
export const API_URL = 'https://kkoomming.kr/api/ADMIN/v1.5/'
export const API_URL2 = 'https://kkoomming.kr/api/ADMIN/v3.0/'